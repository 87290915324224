<template>
  <div class="create-quiz">
    <div class="create-quiz__header">
      <mt-header fixed :title="$t('TeacherQuizList.title.createQuiz')">
        <!-- <router-link to="/teacher/quiz-list" slot="left"> -->
          <mt-button slot="left" icon="back" @click="$emit('click-back-button')"></mt-button>
        <!-- </router-link> -->
      </mt-header>
    </div>
    <div class="create-quiz__main">
      <mt-field
        v-model.trim="quizName"
        class="create-quiz__quiz-name"
        :placeholder="$t('TeacherQuizList.placeholder.quizName')"
        type="text"
        disableClear>
      </mt-field>
<!--
      <mt-field
        v-model.trim="quizDescription"
        class="create-quiz__quiz-description"
        :placeholder="$t('TeacherQuizList.placeholder.quizDescription')"
        type="text"
        disableClear>
      </mt-field>
-->
    </div>
    <div class="create-quiz__footer">
      <mt-button
        class="create-quiz__done-button"
        type="primary"
        @click="done()">
        {{ $t('TeacherQuizList.label.creationDone') }}
      </mt-button>
    </div>
  </div>
</template>

<script>
  import Toast     from '@/components/__mint-ui/Toast';
  import Indicator from '@/components/__mint-ui/Indicator';

  import urls from '@/api/teacher-urls';
  import webviewService from '@/services/webview';

  import { EventBus } from '@/services/event-bus';

  import { findWhere } from 'underscore';

  export default {
    data () {
      return {
        quizName: '',
        quizDescription: '',
      };
    },

    computed: {
      questionsCountIsValid () {
        return (this.questionsCount > 0) && (this.questionsCount <= 100);
      }
    },

    methods: {
      __createQuiz ({ quizName, quizDescription }) {
        const url = urls['quizzes']();
        return this.$http.post(url, { quizName, quizDescription });
      },

      done () {
        if (!this.quizName) {
          Toast.showWarning(this.$i18n.t('TeacherQuizList.warning.inputQuizName'));
        }
        else {
          Indicator.open();
          this.__createQuiz({ quizName: this.quizName, quizDescription: this.quizDescription })
              .then(
                rep => {
                  Indicator.close();
                  this.$emit('click-back-button');
                  this.$router.push({ name: 'teacherQuiz', params: { quizId: rep.data.quizId, quizName: rep.data.quizName } });
                  Toast.showTip('创建成功');
                },
                err => {
                  Indicator.close();
                }
              );
        }
      }
    },

    mounted () {
      window.setTimeout(() => { this.$el.querySelector('input').focus(); }, 300);
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('quizList.createQuiz', () => this.$emit('click-back-button'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('quizList.createQuiz');
    }
  };
</script>

<style lang="scss">
  @import 'src/assets/style/variables';

  .create-quiz {
    &__header {
      position: relative;
      height: $header-height;
    }

    &__main {
      margin-top: .5rem;
    }

    &__footer {
      text-align: center;
    }

    &__done-button {
      margin-top: .5rem;
      width: 90%;
    }
  }
</style>
