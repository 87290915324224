import Vue from 'vue'
import MessageBox from '@/components/__mint-ui/MessageBox';
import Toast from '@/components/__mint-ui/Toast';
import semver from 'semver';
import store from '@/store';
import webviewService from '@/services/webview';

import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';

const ANDROID_UPDATE_STATUS = {
    queued: null,
    downloading: false,
    downloaded: false,
    installing: false
}

export default {

    /**
     * Check for updates
     * Autorun on app start with manual = false
     * @param {*} manual - Whether the check is manual 
     */
    async checkUpdates(manual = true) {

        const currentVersion = store.state.deviceInfo.appVersion;

        if (store.state.deviceInfo.platform === 'web') {
            this.pwa_update(manual)
        }

        if (store.state.deviceInfo.platform === 'android') {

            // get latest version data
            const latestVersionData = await this.android_getLatestVersionInfo().catch(err => {
                console.error(err)
            });
            const latestVersion = latestVersionData?.apkData?.versionName;

            // compare with current version
            const validUpdate = semver.lt(currentVersion, latestVersion);

            // if not valid update, show message for manual requests
            if (!validUpdate) {
                if (manual)
                    MessageBox.open({
                            message: '已经是最新版本',
                            closeOnClickModal: true,
                            showConfirmButton: true,
                            showCancelButton: false,
                            confirmButtonText: '确定',
                        },
                        () => {},
                        () => {}
                    );
                return;
            }

            // if valid update
            ANDROID_UPDATE_STATUS.queued = latestVersionData;

            // if manual request, show message box
            if (manual) {
                MessageBox.open({
                        message: '检测到了新版本。前往更新？',
                        closeOnClickModal: true,
                        showConfirmButton: true,
                        showCancelButton: true,
                        confirmButtonText: '更新',
                        cancelButtonText: '忽略'
                    },
                    async () => {

                        // request storage permission
                        const permission = await Filesystem.requestPermissions().catch(err => {
                            console.error(err)
                        });

                        if (permission?.publicStorage !== 'granted') {
                            Toast.showWarning('请授予存储权限');
                            return;
                        }

                        // download apk
                        this.android_downloadApk(latestVersionData);                    
                    },
                    () => {}
                );
            } 
            // if not manual, show toast
            else {
                Toast.showMessage('检测到了新版本')
            }
        }
    },


    pwa_update(manual) {
        if (window.updateAvailable) {
            MessageBox.open({
                    message: '检测到了新版本。现在更新？',
                    closeOnClickModal: true,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: '更新',
                    cancelButtonText: '忽略'
                },
                () => window.location.reload(true),
                () => {}
            );
        } else {
            if (manual)
                MessageBox.open({
                        message: '已经是最新版本',
                        closeOnClickModal: true,
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: '确定',
                    },
                    () => {},
                    () => {}
                );
        }
    },

    async android_downloadApk(apkData) {

        if (ANDROID_UPDATE_STATUS.downloading) {
            Toast.showMessage('正在下载')
            return;
        }

        // check if apk already in folder
        const readFile = await Filesystem.stat({
            path: apkData.apkData.outputFile,
            directory: Directory.Documents
        }).catch(err => {
            console.error(err)
        });

        if (readFile?.uri) {

            Toast.showTip('已下载');

            await FileOpener.open({ 
                filePath: readFile.uri,
                openWithDefault: false
            });

            return;
        }

        ANDROID_UPDATE_STATUS.downloading = true;

        const downloadUrl = `${process.env.VUE_APP_BASE_URL}/apks/current/${apkData.path}`;

        Toast.showMessage('正在下载');

        const { path } = await webviewService.downloadFile(downloadUrl, {
            onProgress: (progress) => {
                console.log('progress', progress);
            },
            fileName: apkData.apkData.outputFile
        }).catch(err => {
            console.error(err);
            Toast.showWarning('下载失败');
        });

        ANDROID_UPDATE_STATUS.downloading = false;

        if (path) {
            Toast.showTip('下载成功');
            await FileOpener.open({ 
                filePath: path
            });
        }
    },


    async android_getLatestVersionInfo() {

        const versionJsonUrl = `${process.env.VUE_APP_BASE_URL}/apks/current/output.json`

        const resp = await fetch(versionJsonUrl);

        const data = await resp.json();

        if (data && data[0]) return data[0]

        return null;
    }


}