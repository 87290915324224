import store from '@/store'
import updateService from './update'
import Toast from '@/components/__mint-ui/Toast';

import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Clipboard } from '@capacitor/clipboard';
import { Dialog } from '@capacitor/dialog';
import { Network } from '@capacitor/network';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Preferences } from '@capacitor/preferences';
import { Directory, Filesystem } from '@capacitor/filesystem';

export default {
    startScan(url, onFinish) {
        if (window.___env === 'webview')
            window.___startScan(url, onFinish);
    },

    async exitApp() {
        if (store.state.deviceInfo.platform === 'web') return;

        try {
            let confirmRet = await Dialog.confirm({
                title: '退出',
                message: '确认退出门口易测吗?'
            });
            if (confirmRet.value) App.exitApp()
        } catch (err) {
            window.__err = err
        }
    },

    pushBackButtonCallback(key, callback) {
        if (window.___env === 'webview')
            window.___backbuttonCallbacks.push({
                key,
                callback
            });
    },

    popBackButtonCallback(key) {
        if (window.___env === 'webview')
            window.___backbuttonCallbacks = window.___backbuttonCallbacks.filter(c => c.key !== key);
    },

    wechatIsInstalled() {
        if (window.___env === 'webview')
            return window.___wechatIsInstalled ? true : false;
    },

    shareAppToWechat(type) {
        if (window.___env === 'webview')
            window.___shareAppToWechat(type);
    },

    authByWechat() {
        if (window.___env === 'webview')
            return window.___authByWechat();
    },

    openLink(link) {
        return Browser.open({
            url: link
        });
    },

    browserOpen(options) {
        return Browser.open(options);
    },

    async update(manual = true) {
        updateService.checkUpdates(manual);
    },

    changeStatusBarColor(colorStr) {
        try {
            StatusBar.setBackgroundColor({
                color: colorStr
            })
        } catch (err) {
            window.__err = err
        }
    },

    hideSplashScreen() {
        if (store.state.deviceInfo.platform === 'web') return;
        SplashScreen.hide();
    },

    downloadImage(url, onSuccess, onError) {
        if (window.___env === 'webview')
            window.___downloadImage(url, onSuccess, onError);
    },

    async downloadFile(url, { fileName, onProgress }) {
        
        const permission = await Filesystem.requestPermissions().catch(err => {
            console.error(err)
        });

        if (permission?.publicStorage !== 'granted') {
            Toast.showWarning('请授予存储权限');
            return;
        }

        return Filesystem.downloadFile({
            url,
            directory: Directory.Documents,
            path: fileName,
            recursive: true,
            progress: onProgress
        });
    },

    copyToClipboard(string) {
        Clipboard.write({
            string: string
        });
    },

    checkIsAndroid4() {
        if (window.___env === 'webview')
            return window.___checkIsAndroid4();
    },

    acoustic() {
        if (window.___env === 'webview')
            window.___nativeAcoustic();
    },

    haptic() {
        if (store.state.deviceInfo.platform === 'web') {
            try {
                window.navigator.vibrate(35)
            } catch (err) {
                console.log(err)
            }
        } else {
            try {
                Haptics.impact({
                    style: ImpactStyle.Light
                });
            } catch (err) {
                window.__err = err
                window.navigator.vibrate(35)
            }
        }
    },

    // Cordova  webview rely on 'cordova network information plugin'
    // Gradecam webview rely on 'browser web api'
    async getNetworkStatus() {
        let status = await Network.getStatus();
        if (status && status.connected) return 'online';
        return 'offline'
    },

    finishScan(result) {
        if (window.___env === 'webview_scan')
            window.___finishScan(result);
    },

    get_mId() {

        let getUrlParameter = (name, search) => {
            name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
            let results = regex.exec(search);
            return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        };

        let pathname = location.pathname.replace(/\/$/, '');
        let pageFile = pathname.substr(pathname.lastIndexOf('/') + 1).toLowerCase();

        //differentiate 'scan.html' vs 'index.html'

        if (pageFile.indexOf('scan') >= 0)
            return getUrlParameter('mId', location.hash);
        else
            return window.localStorage.getItem('mId');

    },

    get_uav() {
        if (window.___env === 'webview') {
            let getUrlParameter = (name, search) => {
                name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
                let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
                let results = regex.exec(search);
                return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
            };

            let pathname = location.pathname.replace(/\/$/, '');
            let pageFile = pathname.substr(pathname.lastIndexOf('/') + 1).toLowerCase();

            //differentiate 'scan.html' vs 'index.html'

            if (pageFile.indexOf('scan') >= 0)
                return getUrlParameter('uav', location.hash);
            else
                return window.AppVersionManager.current_uav();
        }
    },

    check_web_unsupported() {
        let unsupported = false;

        // if (/MicroMessenger/.test(navigator.userAgent)) {
        //   unsupported = true;
        // }

        if (store.state.deviceInfo.operatingSystem === 'ios') {
            const webkit = !!navigator.userAgent.match(/WebKit/i);
            const iOSSafari = webkit && !/crios|mqbhd|baidu|ucbrowser|aliapp|sogou/.test(navigator.userAgent.toLowerCase())
            if (!iOSSafari) unsupported = true;
        }

        return unsupported;
    }
};