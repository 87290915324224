<template>
    <div class="quiz-list">
        <div v-if="quizListVisible">
            <div v-if="quizzes === null && !loading">
                <div class="quiz-list__header">
                    <mt-header fixed title="测验"></mt-header>
                </div>
                <div class="quiz-list__main is-retry">
                    <div class="quiz-list__retry"  @click="retry__fetchQuizzes()">
                        测验列表获取失败，点击重试
                    </div>
                </div>
                <div class="quiz-list__footer">
                    <footer-tab-bar current-tab="quiz" fixed></footer-tab-bar>
                </div>
            </div>
            <div v-else>
                <div class="quiz-list__header">
                    <mt-header fixed :title="$t('TeacherQuizList.title.quiz')"></mt-header>
                    <span
                        class="quiz-list__new-button"
                        @click="createQuizVisible = true">
                        {{ $t('TeacherQuizList.label.new') }}
                    </span>
                </div>
                <div class="quiz-list__main">
                    <div v-if="quizzes.length > 0">
                        <list-transition>
                            <quiz-card v-for="quiz in quizzes" :quiz="quiz" :key="quiz.quizId" @duplicate-quiz-done="retry__fetchQuizzes()">
                            </quiz-card>
                        </list-transition>
                        <div class="quiz-list__count" @click="fetchQuizzes">
                            共{{ quizzesTotal }}个测验
                            <a style="color:#26a2ff">显示更多</a>
                        </div>
                    </div>
                    <div class="quiz-list__create-quiz" v-else>
                        <div class="quiz-list__create-quiz-prompt">
                            您尚未创建测验，请添加
                        </div>
                        <mt-button class="quiz-list__create-quiz-button" type="primary" @click="createQuizVisible = true">
                            创建测验
                        </mt-button>
                    </div>
                </div>
                <div class="quiz-list__footer" v-if="active">
                    <back-top></back-top>
                    <footer-tab-bar current-tab="quiz" fixed></footer-tab-bar>
                </div>
                <top-refresh @refresh="retry__fetchQuizzes()"></top-refresh>
            </div>
        </div>
        <slide-transition>
            <create-quiz @click-back-button="createQuizVisible = false" v-if="createQuizVisible">
            </create-quiz>
        </slide-transition>
    </div>
</template>
<script>
    import ListTransition from '@/components/ListTransition';
    import SlideTransition from '@/components/SlideTransition';
    import TopRefresh from '@/components/TopRefresh';
    import BackTop from '@/components/BackTop';
    import FooterTabBar from '@/components/teacher/FooterTabBar';

    import QuizCard from './_quiz-list/QuizCard';
    import CreateQuiz from './_quiz-list/CreateQuiz';

    import Toast from '@/components/__mint-ui/Toast';
    import Indicator from '@/components/__mint-ui/Indicator';

    import checkAccess from '@/services/check-access';
    import urls from '@/api/teacher-urls';

    import { without } from 'underscore';

    import { EventBus } from '@/services/event-bus';

    export default {
        name: 'QuizList',
        components: {
            ListTransition,
            SlideTransition,
            TopRefresh,
            BackTop,
            FooterTabBar,
            QuizCard,
            CreateQuiz
        },

        data() {
            return {
                quizzes: null,
                quizzesTotal: null,

                createQuizVisible: false,

                retryVisible: false,

                finished: false,
                loading: false,

                scroll: 0,
                active: false
            };
        },

        computed: {
            quizListVisible() {
                return !this.createQuizVisible;
            },

            networkStatus() {
                return this.$store.state.networkStatus;
            }
        },

        watch: {
            networkStatus(val, oldVal) {
                if (val === 'online' && oldVal === 'offline')
                    if (this.retryVisible) this.retry__fetchQuizzes();
            }
        },

        methods: {
            __fetchQuizzes() {
                const url = urls['quizzes']({
                    limit: (this.quizzes?.length || 0) < 10 ? 10 : 50,
                    offset: Math.max((this.quizzes?.length || 0) - 2, 0)
                });
                return this.$http.get(url);
            },

            fetchQuizzes() {
                if (this.loading) return;

                this.loading = true;

                Indicator.open(this.$i18n.t('__Common.loading'));

                this.__fetchQuizzes().then(
                    rep => {
                        let newQuizzes = 0;
                        if (!this.quizzes) this.quizzes = [];
                        rep.data.quizzes.forEach(quiz => {
                            // add to this.quizzes if not already in
                            if (!this.quizzes.find(q => q.quizId === quiz.quizId))
                                this.quizzes.push(quiz), newQuizzes++;
                        });
                        this.quizzesTotal = rep.data.quizzesTotal;
                        if (!newQuizzes && this.quizzes?.length) {
                            Toast.showTip('没有更多了');
                        }
                        Indicator.close();
                        this.loading = false;
                    },
                    err => {
                        this.retryVisible = true;
                        Indicator.close();
                        this.loading = false;
                        this.finished = true;
                    }
                );
            },

            retry__fetchQuizzes() {
                this.retryVisible = false;
                this.quizzes = null;
                this.quizzesTotal = null;
                this.page = 1;
                this.finished = false;
                this.fetchQuizzes();
            },

            handleScroll(e) {
                this.scroll = e.target.scrollingElement.scrollTop;
            },

            handleEvent_quizUpdated(quiz) {
                if (quiz?.quizId) {
                    // find the quiz in this.quizzes
                    const index = this.quizzes.findIndex(q => q.quizId === quiz.quizId);
                    if (index !== -1) {
                        // update the quiz in this.quizzes
                        const updatedQuiz = Object.assign({}, this.quizzes[index], quiz);
                        this.quizzes.splice(index, 1, updatedQuiz);
                    } else {
                        this.retry__fetchQuizzes();
                    }
                }

            },

            handleEvent_quizDeleted(quizId) {
                // if (quizId) {
                //     this.quizzes = without(this.quizzes, this.quizzes.find(q => q.quizId === quizId));
                // }
                this.retry__fetchQuizzes();
            },

            handleEvent_quizCreated() {
                this.retry__fetchQuizzes();
            }
        },



        created() {
            this.fetchQuizzes();
        },

        beforeCreate() {
            checkAccess('loggedIn');
        },

        activated() {
            // scroll to the last scroll position
            this.$nextTick(() => {
                window.scrollTo(0, this.scroll || 0);
            });

            // add scroll event listener to html
            window.addEventListener('scroll', this.handleScroll);
            this.active = true;
        },

        deactivated() {
            // remove scroll event listener from html
            window.removeEventListener('scroll', this.handleScroll);
            this.active = false;
        },

        mounted() {
            EventBus.$on('quiz-updated', this.handleEvent_quizUpdated);
            EventBus.$on('quiz-deleted', this.handleEvent_quizDeleted);
        },

        destroyed() {
            EventBus.$off('quiz-updated', this.handleEvent_quizUpdated);
            EventBus.$off('quiz-deleted', this.handleEvent_quizDeleted);
        }
    }
</script>
<style lang="scss" scoped>
    @import 'src/assets/style/variables';
    @import 'src/assets/style/mixins';

    .quiz-list {
        &__header {
            position: relative;
            height: $header-height;
        }

        &__main {
            margin-top: .5rem;
            padding: 0 .3rem;

            &.is-retry {
                margin-top: 0;
                padding: 0 0 0 .3rem;
                background-color: $white;
                border-bottom: 1px solid $light-gray;
            }
        }

        &__retry {
            height: 2rem;
            font-size: $h5;
            line-height: 2rem;
            text-align: center;
        }

        &__count {
            padding: .6rem 0;
            color: $dark-gray;
            font-size: $h4;
            text-align: center;
        }

        &__create-quiz {
            text-align: center;
        }

        &__create-quiz-prompt {
            padding: .5rem 0 1rem 0;
            font-size: $h4;
        }

        &__create-quiz-button {
            margin-bottom: 1rem;
            width: 90%;
        }

        &__footer {
            height: 1.3rem;
        }

        &__new-button {
            @include pressed(0, 0, 0, 0);
            position: fixed;
            z-index: $highest-z-index;
            top: 0;
            right: 0;
            padding-right: .3rem;
            display: inline-block;
            width: 1.5rem;
            height: $header-height;
            color: $white;
            font-size: $h4;
            line-height: $header-height;
            text-align: right;
        }
    }
</style>