<template>
    <div id="app">
        <unsupported v-if="unsupported"></unsupported>
        <template v-else>
            <keep-alive include="QuizList">
                <router-view></router-view>
            </keep-alive>
        </template>
        <network-issue-bar v-show="networkStatus === 'offline'"></network-issue-bar>
    </div>
</template>
<script>
    import Unsupported from '@/entries/Unsupported'
    import MessageBox from '@/components/__mint-ui/MessageBox';
    import NetworkIssueBar from '@/components/NetworkIssueBar';
    import webviewService from '@/services/webview';

    import { App } from '@capacitor/app';
    import { Network } from '@capacitor/network';
    import { TextZoom } from '@capacitor/text-zoom';

    export default {
        data() {
            return {
                unsupported: false
            }
        },
        components: {
            NetworkIssueBar,
            Unsupported
        },
        computed: {
            networkStatus() {
                return this.$store.state.networkStatus;
            }
        },
        methods: {
            showPrompt() {
                MessageBox.open({
                        message: '确认退出门口易测吗?',
                        showConfirmButton: true,
                        showCancelButton: true
                    },
                    () => webviewService.exitApp()
                );
            },
            rootPageBackHandler() {
                history.pushState({}, '', `${location.pathname}${location.hash}`)
                webviewService.exitApp()
            }
        },

        beforeCreate() {

            webviewService.pushBackButtonCallback('app', () => this.showPrompt());
        },
        watch: {
            '$route': {
                handler: function() {
                    window.removeEventListener('popstate', this.rootPageBackHandler);

                    if (/teacherClassroomList|teacherQuizList|teacherProfile/.test(this.$route.name)) {
                        history.pushState({}, '', `${location.pathname}${location.hash}`);
                        window.addEventListener('popstate', this.rootPageBackHandler);
                    }
                }
            }
        },
        created() {
            console.log(this.$store.state.deviceInfo)

            // check for unsupported browsers
            if (this.$store.state.deviceInfo.platform === 'web') {
                this.unsupported = webviewService.check_web_unsupported();
            }
        },
        mounted() {

            // Network Status Change Listener
            Network.addListener('networkStatusChange', (status) => {
                console.log("Network status changed", status);
                if (status.connected) {
                    this.$store.commit('SET_NETWORK_STATUS', {
                        networkStatus: 'online'
                    })
                } else {
                    this.$store.commit('SET_NETWORK_STATUS', {
                        networkStatus: 'offline'
                    })
                }
            });

            // Check Network Status on App Resume
            App.addListener('appStateChange', ({ isActive }) => {
                console.log('App state changed. Is active?', isActive);
                if (isActive) {
                    this.$nextTick(() => {
                        Network.getStatus().then(status => {
                            if (status.connected) {
                                this.$store.commit('SET_NETWORK_STATUS', {
                                    networkStatus: 'online'
                                })
                            } else {
                                this.$store.commit('SET_NETWORK_STATUS', {
                                    networkStatus: 'offline'
                                })
                            }
                        });
                    })
                }
            });

            // Text Zoom - if text zoom is larger than 1, set it to 1
            TextZoom.get().then(({ value }) => {
                if (value > 1) {
                    TextZoom.set({ value: 1 });
                }
            });

            window.addEventListener('popstate', this.rootPageBackHandler);
            window.setTimeout(
                () => {
                    webviewService.hideSplashScreen();
                    webviewService.update(false);
                    setTimeout(() => {
                        webviewService.changeStatusBarColor('#26a2ff');
                    }, 500);
                },
                500
            );
        }
    };
</script>
<style lang="scss">
    @import 'src/assets/style/globals';

    #app {
        margin: 0 auto;
    }
</style>