import 'es6-promise/auto';
// import 'babel-polyfill';
// import adapter from 'webrtc-adapter';

import Vue from 'vue';

import App    from './App';
import i18n   from './i18n';
import router from './router';
import store  from './store';

import './api';
import '@/services/mount-mint';
import '@/services/mount-vtouch';

import MessageBox from '@/components/__mint-ui/MessageBox';
import webviewService from '@/services/webview';
import { fetch_mId } from '@/api/fetch';

// import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ElementUI);

import './registerServiceWorker'
import version from '../package.json'

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// library.add(faUserSecret)

// Vue.component('font-awesome-icon', FontAwesomeIcon)

import { Device } from '@capacitor/device';
import { App as CapacitorApp } from '@capacitor/app';

// console.log('webrtc adapter',adapter.browserDetails.browser)

window.___bootApp = function () {

  // Update version for Matomo
  setTimeout(() => {
    _paq?.push(['setCustomDimension', 1, `${version.version}`]);
  }, 2000);

  async function renderApp () {

    const info = await Device.getInfo();
    const appInfo = await CapacitorApp.getInfo().catch(() => ({}));

    info.appVersion = appInfo?.version || version.version;
    info.appBuild = appInfo?.build || version.build;

    store.commit('SET_DEVICE_INFO', info);

    new Vue({
      el: '#app',
      i18n,
      router,
      store,
      render: h => h(App)
    });
  };

  function showPrompt () {
    const isScanPage = (window.___env === 'webview_scan');
    MessageBox.open(
      {
        message: '服务器出了点状况呀',
        closeOnClickModal: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '重新载入',
        cancelButtonText: isScanPage ? '结束扫描' : '退出应用'
      },
      () => window.location.reload(),
      () => isScanPage ? webviewService.finishScan({ initSuccess: false, detail: 'session' }) : webviewService.exitApp()
    );
  }

  store.dispatch('FETCH_SESSION')
       .then(
         rep => renderApp(),
         err => showPrompt()
       );
};

// const isProduction = process.env.NODE_ENV === 'production';

// if (isProduction) {
  // Producation mode: ___bootApp() in 'deviceready' event callback.
  // Producation mode: handle mId logic in appStart.html.
// }
// else {
  if (window.localStorage.getItem('mId'))
    window.___bootApp();
  else
    fetch_mId().then(rep => {
      window.localStorage.setItem('mId', rep.data.mId);
      window.___bootApp();
    });
// }
