<template>
    <div class="export-report">
        <a class="btn btn-primary" @click="onClickExport">
            <div class="export-card">
                <div class="export-card__icon-container">
                    <i class="icon fas fa-file-excel"></i>
                </div>
                <div class="export-card__text">
                    <div class="export-card__text__filename">
                        <span class="export-card__text__filename__fn">{{ quiz.quizName }}</span>
                        <span class="export-card__text__filename__ext">.xlsx</span>
                    </div>
                    <p class="export-card__text__subtext">
                        {{ downloading ? '下载中...' : 
                            downloadedPath ? '已下载，点击打开' : '点击下载' }}
                    </p>
                </div>
                <div class="export-card__icon-container" v-if="downloadedPath" @click.stop="onClickShare">
                    <i class="icon small fas fa-share-square"></i>
                </div>
            </div>
        </a>
        <div class="desktop-prompt-card" @click="copyDesktopUrl">
            <img src="@/assets/images/laptop.png" alt="电脑下载" class="desktop-prompt-card__img">
            <p class="desktop-prompt-card__prompt-text">手机下载不方便？电脑端网页地址：</p>
            <p class="desktop-prompt-card__site-link">www.mkyice.cn</p>
        </div>
    </div>
</template>
<script>
    import urls from '@/api/teacher-urls';
    import webviewService from '@/services/webview';
    import Toast from '@/components/__mint-ui/Toast';

    import { FileOpener } from '@capacitor-community/file-opener';
    import { Share } from '@capacitor/share';

    export default {
        props: ['quiz'],
        data() {
            return {
                downloading: false,
                downloadedPath: null
            }
        },
        computed: {
            exportUrl() {
                return urls['quizExportReport']({ quizId: this.quiz.quizId });
            }
        },
        methods: {
            copyDesktopUrl() {
                webviewService.copyToClipboard('https://www.mkyice.cn/homepage/web/');
                Toast.showTip('网址已复制到剪贴板');
                _paq?.push(['trackEvent', 'Copy', 'Copy Desktop URL']);
            },
            async onClickExport() {

                if (this.$store.state.deviceInfo.platform === 'web') {
                    window.location.href = this.exportUrl;
                }

                else {

                    if (this.downloading) return;

                    if (this.downloadedPath) {
                        await FileOpener.open({ 
                            filePath: this.downloadedPath, 
                            contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            openWithDefault: false 
                        });
                        return;
                    }

                    this.downloading = true;
                
                    const { path } = await webviewService.downloadFile(this.exportUrl, {
                        onProgress: (progress) => {
                            console.log('progress', progress);
                        },
                        fileName: `${this.quiz.quizName}_${Date.now()}.xlsx`
                    }).catch(err => {
                        console.error(err);
                        Toast.showWarning('下载失败');
                    })

                    this.downloading = false;

                    if (path) {
                        Toast.showTip('下载成功');
                        this.downloadedPath = path;
                        await FileOpener.open({ 
                            filePath: path, 
                            contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            openWithDefault: false 
                        });
                    }
                }
                _paq?.push(['trackEvent', 'Downloads', 'Export Quiz Report']);
            },

            async onClickShare() {
                if (!this.downloadedPath) return;
                await Share.share({ url: `file://${this.downloadedPath}` });
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import 'src/assets/style/variables';

    .export-report {
        padding: .3rem;

        .export-card {
            position: relative;
            margin-top: .25rem;
            padding: .5rem .35rem;
            background-color: $white;
            border-radius: 2%;
            display: flex;
            transition: all .2s;
        }

        .export-card:active {
            background-color: $light-gray;
        }

        .export-card__icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2%;
        }

        .export-card__icon-container .icon {
            font-size: 1rem;
            color: #008000;
        }

        .export-card__icon-container .icon.small {
            font-size: .35rem;
        }

        .export-card__text {
            flex: 1;
            overflow: hidden;
            margin: 0 .5rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .export-card__text__filename {
            font-size: $h4;
            color: $darkest-gray;
            margin-bottom: .1rem;
            overflow: hidden;
            display: flex;
        }

        .export-card__text__filename__fn {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .export-card__text__filename__ext {
            color: $dark-gray;
        }

        .export-card__text__subtext {
            font-size: $h5;
            color: #008000;
        }

        .desktop-prompt-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 2rem;
        }

        .desktop-prompt-card__img {
            max-width: 6rem;
            margin-bottom: .2rem;
        }

        .desktop-prompt-card__prompt-text {
            margin-top: .3rem;
            font-size: $h4;
            color: $darker-gray;
        }

        .desktop-prompt-card__site-link {
            font-size: $h4;
            margin-top: .1rem;
        }
    }
</style>