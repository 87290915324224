<template>
  <div class="create-classroom">
    <div class="create-classroom__header">
      <mt-header fixed :title="$t('TeacherClassroomList.title.createClassroom')">
        <!-- <router-link to="/teacher/classroom-list" slot="left"> -->
          <mt-button slot="left" icon="back" @click="$emit('click-back-button')"></mt-button>
        <!-- </router-link> -->
      </mt-header>
    </div>
    <div class="create-classroom__main">
      <div class="create-classroom__prompt">
        {{ $t('TeacherClassroomList.classroomNamePrompt') }}
      </div>
      <mt-field
        v-model.trim="classroomName"
        class="create-classroom__classroom-name"
        :placeholder="$t('TeacherClassroomList.placeholder.classroomName')"
        type="text"
        disableClear>
      </mt-field>
    </div>
    <div class="create-classroom__footer">
      <mt-button
        class="create-classroom__done-button"
        type="primary"
        @click="done()">
        {{ $t('TeacherClassroomList.label.creationDone') }}
      </mt-button>
    </div>
  </div>
</template>

<script>
  import Toast     from '@/components/__mint-ui/Toast';
  import Indicator from '@/components/__mint-ui/Indicator';

  import urls         from '@/api/teacher-urls';
  import errorHandler from '@/api/error-handler';

  import webviewService from '@/services/webview';

  export default {
    data () {
      return {
        classroomName: ''
      };
    },
    methods: {
      __createClassroom ({ classroomName }) {
        const url = urls['classrooms']();
        return this.$http.post(url, { classroomName });
      },

      done () {
        if (!this.classroomName) {
          Toast.showWarning(this.$i18n.t('TeacherClassroomList.warning.inputClassroomName'));
        }
        else {
          Indicator.open();
          this.__createClassroom({ classroomName: this.classroomName })
              .then(
                rep => {
                  Indicator.close();

                  this.$router.push({ name: 'teacherClassroom', params: { classroomId: rep.data.classroomId, classroomName: rep.data.classroomName } });
                  Toast.showTip('创建成功, 请添加学生');
                },
                err => {
                  Indicator.close();
                  errorHandler.handle(err);
                }
              );
        }
      }
    },

    mounted () {
      window.setTimeout(() => { this.$el.querySelector('input').focus(); }, 300);
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('classroomList.createClassroom', () => this.$emit('click-back-button'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('classroomList.createClassroom');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .create-classroom {
    &__header {
      position: relative;
      height: $header-height;
    }

    &__main {
      margin-top: .3rem;
    }

    &__prompt {
      margin: .1rem .3rem;
      color: $dark-gray;
      font-size: $h5;
      text-align: left;
    }

    &__footer {
      text-align: center;
    }

    &__done-button {
      margin-top: .5rem;
      width: 90%;
    }
  }
</style>
