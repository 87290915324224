import Vue from 'vue';
import VueRouter from 'vue-router';

import teacherRoutes from './teacher-routes';
// import studentRoutes from './student-routes';

import Enter from '@/entries/Enter';
import Unsupported from '@/entries/Unsupported';
import Homepage from '@/entries/Homepage';
import Settings from '@/entries/Settings';
import About from '@/entries/About';
import NotificationList from '@/entries/NotificationList';
import Notification from '@/entries/Notification';

import { union } from 'underscore';

Vue.use(VueRouter);

let routes = union(
    teacherRoutes,
    // studentRoutes,
    [
        { path: '/enter', name: 'enter', component: Enter },
        { path: '/unsupported', name: 'unsupported', component: Unsupported },
        { path: '/homepage', name: 'homepage', component: Homepage },
        { path: '/settings', name: 'settings', component: Settings },
        { path: '/about', name: 'about', component: About },
        { path: '/notification-list', name: 'notificationList', component: NotificationList },
        { path: '/notification/:notificationId', name: 'notification', component: Notification },
        { path: '*', name: 'default', component: Homepage }
    ]
);

const router = new VueRouter({ routes });

// Track page views with Matomo
router.afterEach((to) => {
    _paq?.push(['setCustomUrl', '/' + to.path]);
    _paq?.push(['setDocumentTitle', to.name]);
    _paq?.push(['trackPageView']);
});

export default router;