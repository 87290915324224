<template>
    <div class="download-bubblesheet-modal">
        <div class="download-bubblesheet-modal__content">
            <div class="download-bubblesheet-modal__title">
                <span class="download-bubblesheet-modal__back-button" @click="$emit('close-modal')">
                    <back-icon></back-icon>
                </span>
                下载答题卡
            </div>
            <div class="download-bubblesheet-modal__body">
                <div class="download-bubblesheet-modal__set-stid-digits-prompt">
                    答题卡学号位数
                </div>
                <div class="download-bubblesheet-modal__set-stid-digits" v-show="!initiating">
                    <mt-field placeholder="学号位数（3-10）之间" type="number" :attr="{ min: 3, max: 10 }" v-model="digits" ref="digits-field"></mt-field>
                </div>
                <div class="download-bubblesheet-modal__bbs-display-container">
                    <div class="loading-container" v-show="initiating || (digitDebounce && validDigits)">
                        <mt-spinner :size="100" type="fading-circle" color="#26a2ff"></mt-spinner>
                    </div>
                    <div class="download-bubblesheet-modal__bbs-img-container" v-if="!digitDebounce && validDigits">
                        <img :src="downloadUrls.png" alt="bubblesheet" ref="bbsPng" crossorigin="anonymous" @load="handleImageOnload" @error="handleImageError" v-show="!error">
                        <div class="download-bubblesheet-modal__prompt" v-if="error">
                            生成失败, 请稍后再试
                        </div>
                    </div>
                    <div class="download-bubblesheet-modal__prompt" v-if="!validDigits">
                        请设置学号位数（3-10）之间
                    </div>
                </div>
            </div>
            <div class="download-bubblesheet-modal__buttons-container">
                <mt-button class="download-bubblesheet-modal__download-button" type="primary" :disabled="!canDownload" @click="downloadBubblesheet">
                    确认下载
                </mt-button>
            </div>
        </div>
        <div class="download-bubblesheet-modal__mask" @click="$emit('close-modal')">
            <mask-layer></mask-layer>
        </div>
    </div>
</template>
<script>
    import BackIcon from '@/components/BackIcon';
    import MaskLayer from '@/components/MaskLayer';

    import Toast from '@/components/__mint-ui/Toast';

    import urls from '@/api/teacher-urls';

    import webviewService from '@/services/webview';

    import { FileOpener } from '@capacitor-community/file-opener';


    export default {
        components: {
            BackIcon,
            MaskLayer
        },

        props: ['quiz', 'downlodQuery'],

        data() {
            return {
                downloadUrls: {
                    html: '/bubblesheet.html',
                    pdf: '',
                    png: ''
                },
                sendToEmailModalVisible: false,
                loading: false,
                error: false,
                initiating: true,
                digits: 0,
                digitDebounce: null
            }
        },
        computed: {
            validDigits() {
                if (!this.digits) {
                    return false
                }
                const digit = parseInt(this.digits);
                return digit >= 3 && digit <= 10
            },
            canDownload() {
                return !this.error && !this.loading && this.validDigits && !this.digitDebounce && this.downloadUrls.png?.length
            }
        },
        methods: {
            __fetchClassrooms({
                quizId
            }) {
                const url = urls['quizDataClassrooms']({
                    quizId
                })
                return this.$http.get(url)
            },
            generateBbsPngUrl() {

                this.loading = true;
                this.error = false;

                let _query = this.downlodQuery;

                if (this.digits) {
                    _query += `&d=${this.digits}`
                }

                const b64 = btoa(_query);

                this.downloadUrls.png = `https://mkyice-bbs.menco.cn/img/${b64}`;
            },
            handleDigitChange() {
                if (this.digitDebounce) {
                    clearTimeout(this.digitDebounce)
                }
                this.digitDebounce = setTimeout(() => {
                    this.generateBbsPngUrl();
                    this.digitDebounce = null;
                }, 1000)
            },
            handleImageOnload() {
                console.log('image loaded');
                this.loading = false;
            },
            handleImageError() {
                console.log('image error');
                this.loading = false;
                this.error = true;
            },
            async downloadBubblesheet() {

                if (!this.canDownload) {
                    return
                }

                if (this.$store.state.deviceInfo.platform === 'web') {

                    const imageElement = this.$refs.bbsPng;

                    // Create a new Image object to get the original size
                    const img = new Image();
                    img.crossOrigin = "anonymous";
                    img.src = imageElement.src;

                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        canvas.width = img.naturalWidth; // Use original width
                        canvas.height = img.naturalHeight; // Use original height

                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);

                        const dataUrl = canvas.toDataURL('image/png');

                        const link = document.createElement('a');
                        link.href = dataUrl;
                        link.download = `${this.quiz?.quizName}_答题卡${Date.now()}.png`;
                        link.click();
                    };

                }

                else {

                    const { path } = await webviewService.downloadFile(this.downloadUrls.png, {
                        onProgress: (progress) => {
                            console.log('progress', progress);
                        },
                        fileName: `${this.quiz.quizName}_答题卡${Date.now()}.png`
                    }).catch(err => {
                        console.error(err);
                        Toast.showWarning('下载失败');
                    })

                    if (path) {
                        Toast.showTip('下载成功');
                        await FileOpener.open({ 
                            filePath: path,
                            openWithDefault: false 
                        });
                    }
                }

                _paq?.push(['trackEvent', 'Downloads', 'Download Bubblesheet']);
            }
        },
        mounted() {

            this.__fetchClassrooms({
                    quizId: this.quiz.quizId
                })
                .then(rep => {
                    let classrooms = rep.data.classrooms

                    const students = classrooms.reduce((acc, cur) => {
                        return acc.concat(cur.students)
                    }, [])

                    // sort according to studentQuizNumber, longest to shortest
                    students.sort((a, b) => b.studentQuizNumber.length - a.studentQuizNumber.length)

                    this.digits = students[0].studentQuizNumber.length

                    this.initiating = false

                }).catch(err => {

                    this.digits = 8;

                    this.initiating = false

                });
        },
        watch: {
            digits() {
                this.handleDigitChange()
            }
        },
        beforeCreate() {
            webviewService.pushBackButtonCallback(
                'quiz.bubblesheet.downloadBubblesheetModal', 
                () => this.$emit('close-modal')
            )

        },
        beforeDestroy() {
            webviewService.popBackButtonCallback('quiz.bubblesheet.downloadBubblesheetModal')
        }
    };
</script>
<style lang="scss" scoped>
    @import 'src/assets/style/variables';

    .download-bubblesheet-modal {
        &__content {
            position: fixed;
            z-index: $highest-z-index + 2;
            top: 1.9rem;
            left: 2%;
            width: 96%;
            height: 14rem;
            max-height: 80vh;
            background-color: $white;
            border-radius: 0.1rem;
        }

        &__title {
            height: 1.2rem;
            background-color: $blue;
            border-radius: 0.1rem 0.1rem 0 0;
            color: $white;
            font-size: $h4;
            line-height: 1.2rem;
            text-align: center;
        }

        &__body {
            position: absolute;
            top: 1.2rem;
            left: 0;
            right: 0;
            bottom: 2rem;
            overflow: hidden;
            padding: 0.2rem;
            display: flex;
            flex-direction: column;
        }

        &__back-button {
            position: absolute;
            top: 0;
            left: 0.15rem;
        }

        &__send-button {
            position: absolute;
            right: 0.3rem;
            font-size: $h5;

            &:active {
                opacity: 0.5;
            }
        }

        &__set-stid-digits-prompt {
            margin: .5rem 0 .1rem 0;
            padding-left: .2rem;
            font-size: $h5;
            font-weight: $bold;
        }

        &__set-stid-digits {
            margin-top: .2rem;
            width: 100%;
        }

        &__bbs-display-container {
            position: relative;
            flex: 1;
            overflow: hidden;
        }

        &__prompt {
            position: absolute;
            top: 4rem;
            left: 0;
            width: 100%;
            font-size: $h3;
            text-align: center;
        }

        &__bubblesheet-container {
            position: absolute;
            left: 0;
            right: 0;
            top: 3.6rem;
            bottom: 0;
            height: 6.2rem;
            overflow: hidden;
            padding: 0.2rem;
        }

        &__bbs-img-container {
            width: 100%;
            height: 100%;
            overflow: auto;
        }

        &__iframe {
            position: relative;
            width: 100%;
            height: 100%;
            zoom: 0.95;
            -moz-transform: scale(0.95);
            -moz-transform-origin: 0 0;
            -o-transform: scale(0.95);
            -o-transform-origin: 0 0;
            -webkit-transform: scale(0.95);
            -webkit-transform-origin: 0 0;
        }

        &__buttons-container {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2rem;
            overflow: hidden;
        }

        &__download-button {
            margin: 0.5rem 5%;
            width: 90%;
        }

        .loadIcon {
            width: 100%;
            height: 6.5rem;
            margin-top: 1.25rem;
            position: absolute;
            top: 1.2rem;
            left: 0;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .loading-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(255, 255, 255, 0.8);
        }

        // .mint-cell.mint-field {
        //   ::v-deep .mint-cell-wrapper {
        //     display: flex;
        //     border: 0.02rem solid #26a2ff;
        //     background-color: #26a2ff;
        //     .mint-cell-title {
        //       width: 240px;
        //     }
        //     .mint-cell-value {
        //       height: 1rem;
        //       padding-left: 0.5rem;
        //       padding-right: 0.2rem;
        //       background-color: white;
        //       .mintui.mintui-field-error {
        //         font-size: 0.5rem;
        //       }
        //     }
        //   }
        // }
    }
</style>