import { Preferences } from '@capacitor/preferences';

let scannerState = {
    
    state: {
        lowQualityAdapter: false
    },

    getters: {
        lowQualityAdapter: state => state.lowQualityAdapter
    },

    mutations: {

        TOGGLE_LQADAPTER(state) {

            state.lowQualityAdapter = !state.lowQualityAdapter;

            if (state.lowQualityAdapter) {
                window.scanner_adapter = 'lq'
            } else {
                window.scanner_adapter = null
            }

            Preferences.set({
                key: 'lowQualityAdapter',
                value: JSON.stringify(state.lowQualityAdapter)
            });
        }
    },
}

let initLowQualityAdapter = false;

Preferences.get({
    key: 'lowQualityAdapter'
}).then(get_lowQualityAdapter => {

    initLowQualityAdapter = JSON.parse(get_lowQualityAdapter?.value)

    if (typeof initLowQualityAdapter !== 'boolean') initLowQualityAdapter = false;

    if (initLowQualityAdapter) scannerState.state.lowQualityAdapter = true, window.scanner_adapter = 'lq';
});

export default scannerState;